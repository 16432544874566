<template>
    <form v-bind="attributes" v-on="listeners">
        <slot />
    </form>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "",
        };
    },
    computed: {
        classes() {
            return `form-form ${this.baseClasses}`;
        },
        attributes() {
            return {
                class: this.classes,
                ...this.$attrs,
            };
        },
        listeners() {
            return {
                ...this.$listeners,
            };
        },
    },
};
</script>
